
import { Component, Prop, Vue } from 'vue-property-decorator'
import PaginationButton from './PaginationButton.vue';
import NumPerPageSelect from './NumPerPageSelect.vue';

@Component({ 
  components:{ 
    PaginationButton, 
    NumPerPageSelect
  }
})
export default class Pagination extends Vue {
  @Prop()
  rows !: { [key: string]: any }[]

  numPerPage = 10;

  page = 0

  get filteredRows (): { [key: string]: any }[] {
    return this.rows.filter((r, i) => i >= this.startItemNumber && i <= this.endItemNumber)
  }

  get numPages (): number {
    return Math.max(Math.ceil(this.total / this.numPerPage), 1)
  }

  get total () : number {
    return this.rows.length
  }

  get startItemNumber () : number {
    return this.page * this.numPerPage
  }

  get endItemNumber () : number {
    return Math.min((this.page + 1) * this.numPerPage, this.total) - 1
  }

  get shownPageNumbers () : number [] { 
    const numbers: number[] = [];
    if (this.page > 1){ 
      numbers.push(this.page - 2);
    }
    if (this.page > 0){ 
      numbers.push(this.page -1);
    }
    numbers.push(this.page);
    if (this.page < this.numPages - 1){ 
      numbers.push(this.page + 1);
    }
    if (this.page < this.numPages - 2){ 
      numbers.push(this.page + 2);
    }
    return numbers;
  }

  goToPage (page: number)  {
    this.page = page
  }

  async onNumPerPageChange(numPerPage: string){
    this.$emit('numperpage', numPerPage);
  }
}
