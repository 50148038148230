
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class NumPerPageSelect extends Vue {
  @Prop()
  value!: string;

  get val(){
    return this.value;
  }

  set val(value: string){
    this.$emit('input', value);
  }
}
